"use client";

import { ReactNode, useState } from "react";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

export type Props = {
  testimonials: Array<{ quote: ReactNode; author: ReactNode }>;
};

export const TestimonialCarousel = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previous = () => {
    setCurrentIndex(
      (currentIndex - 1 + props.testimonials.length) %
        props.testimonials.length,
    );
  };
  const next = () => {
    setCurrentIndex((currentIndex + 1) % props.testimonials.length);
  };

  return (
    <div className="w-full flex gap-5 lg:gap-10 items-stretch py-5 pl-5 border-l-8 border-blue-50">
      <button
        onClick={() => previous()}
        aria-label="Previous testimonial"
        className="shrink-0 basis-12 md:basis-24 group p-2 lg:p-5"
      >
        <MdNavigateBefore size={50} className="group-hover:fill-blue-500" />
      </button>
      {props.testimonials.map((testimonial, index) => (
        <blockquote
          key={`testimonial-${index}`}
          className={`grow flex-col gap-3 ${index === currentIndex ? "flex" : "hidden"}`}
        >
          <div className="text-lg md:text-xl lg:text-3xl">
            {testimonial.quote}
          </div>
          <footer className="text-md md:text-lg lg:text-xl text-right">
            <cite>{testimonial.author}</cite>
          </footer>
        </blockquote>
      ))}
      <button
        onClick={() => next()}
        aria-label="Next testimonial"
        className="shrink-0 basis-12 md:basis-24 group p-2 lg:p-5"
      >
        <MdNavigateNext size={50} className="group-hover:fill-blue-500" />
      </button>
    </div>
  );
};
